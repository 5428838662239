import React from "react";
import AwardeesCard from "./AwardeesCard";
import "./AwardeesCard.css";

import drGervanFearon from "../img/Awardees/dr-gervan-fearon.jpeg";
import harrietThornhill from "../img/Awardees/harriet-thornhill.jpeg";
import nicholasThompson from "../img/Awardees/nicholas-thompson.jpeg";
import rowanBarrett from "../img/Awardees/rowan-barrett.jpg";
import senatorMurraySinclair from "../img/Awardees/senator-murray-sinclair.jpeg";
import wandaThomasBernard from "../img/Awardees/wanda-thomas-bernard.jpeg";
import kamalajeangopie from "../img/Awardees/kamala-jean-gopie.jpg"
import winstonlarose from "../img/Awardees/winston-larose.jpg"
import michaëlleJean from "../img/Awardees/michaëlle-jean.jpg"
import gregFergus from "../img/Awardees/greg-fergus.jpg";
import patriciaBebiaMawa from "../img/Awardees/patricia-bebia-mawa.jpg";
import jeanAugustine from "../img/GuestSpeakers/dr-jean-augustine.jpg"
import justiceDonaldMcleod from "../img/Awardees/justice-donald-mcleod.jpg";
import dianaDsouza from "../img/Awardees/diana-dsouza.jpg";


const awardees = [
  {
    image: jeanAugustine,
    name: "Hon. Dr. Jean Augustine, PC CM OOnt CBE",
    linkedin: "https://www.linkedin.com/in/jean-augustine-ab8092a/?originalSubdomain=ca",
    title: "First Black Woman in the Parliament, Parliamentary Secretary to the Prime Minister; Minister for Multiculturalism and the Status of Women; Chair of the Foreign Affairs Committee; and Deputy Speaker, Founder of Jean Augustine Centre for Girls",
    award: "Nation Builders Legacy Award",
  },
  {
    image: wandaThomasBernard,
    name: "Hon. Wanda Thomas Bernard, Ph.D., C.M., O.N.S.",
    linkedin: "https://www.linkedin.com/in/wanda-thomas-bernard-6076b2aa/",
    title: "The First African Nova Scotian Woman in the Senate Chamber; Former Chair of the Nova Scotia Advisory Council on the Status of Women; Former Professor, Dalhousie University, Halifax",
    award: "Nation Builders Award for Lifetime Achievement",

  },
  {
    image: senatorMurraySinclair,
    name: "Senator Murray Sinclair, CC OM MSC",
    linkedin: "https://www.linkedin.com/in/retired-senator-murray-sinclair-127b2434/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BEDqlxdCGQZOLBZNXpdBTGA%3D%3D",
    title: "Chancellor, Queen’s University; Former Judge and Member of the Canadian Senate; Former Chair of the Indian Residential Schools Truth and Reconciliation Commission of Canada",
    award: "Nation Builders Award for National Unity & Harmony",

  },
  {
    image: michaëlleJean,
    name: "Hon. Michelle Jean PC CC CMM CD FRCPSC",
    linkedin: "https://www.linkedin.com",
    title: "Former Journalist, the 27th & First Haitian & Black Governor General of Canada; Former UNESCO Special Envoy for Haiti",
    award: "Social Justice Icon Award",

  },
  {
    image: gregFergus,
    name: "Hon. Greg Fergus, PC MP",
    linkedin: "https://ca.linkedin.com/in/greg-fergus-18762a267",
    title: "Member of Parliament (MP), Hull—Aylmer, Quebec 38th and 1st Black Speaker, House of Commons of Canada",
    award: "Nation Builders Award for Inclusive Politics & Equitable Governance",
  },
  {
    image: winstonlarose,
    name: "Dr. Winston LaRose",
    linkedin: "https://www.linkedin.com",
    title: "Executive Vice President, Jane-Finch Concerned Citizens Organization, & Co-Founder, the Hamilton Guyanese Canadian Cultural Association",
    award: "Nation Builders Award for Community Development",

  },
  {
    image: harrietThornhill,
    name: "Harriet Thornhill",
    linkedin: "https://www.linkedin.com/in/harriet-thornhill-mba-icd-d-386803b/",
    title: "Former Royal Bank Executive, Board of Director Member, Leadership Coach & Chancellor, Tyndale University",
    award: "Nation Builders Award in Business Excellence",

  },
  {
    image: drGervanFearon,
    name: "Dr. Gervan Fearon O.Ont",
    linkedin: "https://ca.linkedin.com/in/gervan-fearon-94838554",
    title: "President, George Brown College; former President and Vice-Chancellor at Brock University in Ontario and Brandon University in Manitoba & Board Member, Ontario Chamber of Commerce & Niagara Health",
    award: "Nation Builders Award in Education & Academia",
  },
  {
    image: rowanBarrett,
    name: "Rowan Barrett, MBA",
    linkedin: "https://ca.linkedin.com/in/rowan-barrett-mba-30318621",
    title: "2X Olympian; General Manager & Executive Vice President, Canada Basketball; Captained Team Canada to 7th place at the 2000 Sydney Olympics & Achieved Bronze in the 2024 Paris Olympics",
    award: "Nation Builders Award for Inclusive & Equitable Sports",

  },
  {
    image: patriciaBebiaMawa,
    name: "Patricia Bebia Mawa",
    linkedin: "https://www.linkedin.com/in/patriciabebiamawa/?originalSubdomain=ca",
    title: "President/CEO, Diamond Plus Media: Executive Vice President, Silvertrust Media & Afroglobal TV, Movie Producer, Chair Reelworld Screen Institute",
    award: "Nation Builders Award for Media, Arts and Entertainment",
  },
  {
    image: nicholasThompson,
    name: "Nicholas Marcus Thompson",
    linkedin: "https://ca.linkedin.com/in/meetnmt",
    title: "President, and Chief Executive Officer of the Black Class Action Secretariat (BCAS) and Renowned Social Justice Advocate and Activist",
    award: "Social Justice Rising Star Award",

  },
  {
    image: kamalajeangopie,
    name: "Kamala Jean Gopie O.Ont., M. Ed., BA",
    linkedin: "https://www.linkedin.com",
    title: "Board Member, Unity Health Toronto; Former Ontario Human Rights Commissioner; Former Vice-Chair of the United Way of Toronto & Former Governor at the University of Toronto.",
    award: "Nation Builders Award for Global Humanitarian",

  },
  {
    image: justiceDonaldMcleod,
    name: "Hon. Justice Donald F. McLeod",
    linkedin: "https://www.linkedin.com",
    title: "Judge at the Ontario Court of Justice; First Black Graduate from Queens University Law School since 1957 inception; Former Chair of the Federation of Black Canadian; Co-Founder of the 100 Strong Foundation",
    award: "Nation Builders Award for Law & Justice",

  },
  {
    image: dianaDsouza,
    name: "Diana Alli D'Souza, O.Ont",
    linkedin: "https://www.linkedin.com/in/diana-alli-d%E2%80%99souza-o-ont-09672a1b/?originalSubdomain=ca",
    title: "President, Access Empowerment Council, Board Member, Pluralism in Motion; Former Senior Officer, Faculty of Medicine, University of Toronto",
    award: "Nation Builders Award for Youth Empowerment",

  },
];



const AwardeesGrid = () => {
  return (
    <section>
      <div className="header"  id="awardees2024">
        <h1 className="title">Our Honourable <br/>Awardees (2024)</h1>
        <p className="subheader">
          Celebrate the achievements of the awardees who are making an impact.
        </p>
      </div>
      <div className="awardees-grid container">
        {awardees.map((awardees, index) => (
          <AwardeesCard
            key={index}
            image={awardees.image}
            name={awardees.name}
            title={awardees.title}
            linkedin={awardees.linkedin}
            award={awardees.award}
            className="container"
          />
        ))}
      </div>
    </section>
  );
};

export default AwardeesGrid;
