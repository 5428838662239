import React, { useState, useEffect } from "react";
import jsonData from "../assets/data/data.json";
import "./Contact.css";
const ContactInfo = () => {
  const [locationData, setLocationData] = useState(null);
  const [mapData, setMapData] = useState(null);

  useEffect(() => {
    setLocationData(jsonData.location);
    setMapData(jsonData.map);
  }, []);

  if (!locationData || !mapData) {
    return (
      <div className="skeleton-loading">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title">
                <div
                  className="skeleton-text skeleton-title"
                  style={{ width: "80%", marginBottom: "10px" }}
                ></div>
                <div
                  className="skeleton-text"
                  style={{ width: "60%", marginBottom: "20px" }}
                ></div>
              </div>
              <div className="cs-text">
                <div className="ct-address">
                  <span
                    className="skeleton-text"
                    style={{ width: "30%", marginBottom: "10px" }}
                  ></span>
                  <p className="skeleton-text" style={{ width: "80%" }}></p>
                </div>
                <ul>
                  <li>
                    <span
                      className="skeleton-text"
                      style={{ width: "20%" }}
                    ></span>
                    <span
                      className="skeleton-text"
                      style={{ width: "60%" }}
                    ></span>
                  </li>
                  <li>
                    <span
                      className="skeleton-text"
                      style={{ width: "20%" }}
                    ></span>
                    <span
                      className="skeleton-text"
                      style={{ width: "60%" }}
                    ></span>
                  </li>
                </ul>
                <div className="ct-links">
                  <span
                    className="skeleton-text"
                    style={{ width: "30%" }}
                  ></span>
                  <p className="skeleton-text" style={{ width: "80%" }}></p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="cs-map">
                <div className="skeleton-map"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="contact-section x-pad" id="contact">
      <div className="container">
        <div className="contact-row">
          <div className="col-lg-5 x-padding">
            <div className="section-title">
              <h2>{locationData.title}</h2>
              <p>{locationData.description}</p>
            </div>
            <div className="text">
              <div className="address">
                <span>Address:</span>
                <p>
                  {locationData.address}, {locationData.city} <br />{" "}
                  {locationData.province}, {locationData.country}
                </p>
              </div>
              <ul>
                <li>
                  <span>Phone:</span>
                  {locationData.phone}
                </li>
                <li>
                  <span>Email:</span>
                  {locationData.email}
                </li>
              </ul>
              <div className="links">
                <div className="">
                  <a href={locationData.website} target="_blank" rel="noreferrer" className="primary-btn">Book your Discounted Room Today!</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 x-padding">
            <div className="map">
              <iframe
                title="Google Map"
                src={mapData.src}
                height={400}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
