import AwardeesGrid from "../components/AwardeesGrid";
import AwardeesGrid2023 from "../components/AwardeesGrid2023";



const Awardees = () => {
    return (
      <>
        <AwardeesGrid/>
        <AwardeesGrid2023/>
      </>
    );
  };
  
  export default Awardees;