import React from "react";
import scheduleData from "../assets/data/data.json";
import "./ScheduleSection.css";
import image1 from "../img/Schedule/session1.jpg";
import image2 from "../img/Schedule/session2.webp";
import image3 from "../img/Schedule/session3.webp";
import image4 from "../img/Schedule/session4.jpg";
import image5 from "../img/Schedule/session5.jpg";

// Mapping image filenames to imported images
const images = {
  "session1.jpg": image1,
  "session2.jpg": image2,
  "session3.jpg": image3,
  "session4.jpg": image4,
  "session5.jpg": image5,
};

const ScheduleSection = () => {
  return (
    <section className="schedule-section" id="schedule">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 schedule-description">
            <div>
              <h2 className="schedule-title">{scheduleData.title}</h2>
              <p>{scheduleData.description}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div>
              <ul className="nav nav-tabs" role="tablist">
                {scheduleData.days.map((day, index) => (
                  <li className="nav-item" key={index}>
                    <a
                      className={`nav-link ${index === 0 ? "active" : ""}`}
                      data-toggle="tab"
                      href={`#${day.id}`}
                      role="tab"
                    >
                      <h5 className="nav-info">{day.title}</h5>
                      <p className="nav-info">{day.date}</p>
                    </a>
                  </li>
                ))}
              </ul>
              <div className="tab-content">
                {scheduleData.days.map((day, index) => (
                  <div
                    className={`tab-pane ${index === 0 ? "active" : ""}`}
                    id={day.id}
                    role="tabpanel"
                    key={index}
                  >
                    {day.sessions.map((session, i) => (
                      <div className="content" key={i}>
                        <div className="container">
                          <div className="row spacing">
                            <div className="col-lg-5">
                              <div className="text">
                                <h4>{session.title}</h4>
                                <ul>
                                  <li>
                                    <i className="fa fa-book"></i>{" "}
                                    {session.summary}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <ul className="widget">
                                <li>
                                  <i className="fa fa-clock-o"></i>{" "}
                                  {session.time}
                                </li>
                                <li>
                                  <i className="fa fa-user"></i>{" "}
                                  {session.speaker}
                                </li>
                                {session.speaker2 && (
                                  <li>
                                    <i className="fa fa-user"></i>{" "}
                                    {session.speaker2}
                                  </li>
                                )}
                                {session.speaker3 && (
                                  <li>
                                    <i className="fa fa-user"></i>{" "}
                                    {session.speaker3}
                                  </li>
                                )}
                                {session.speaker4 && (
                                  <li>
                                    <i className="fa fa-user"></i>{" "}
                                    {session.speaker4}
                                  </li>
                                )}
                              </ul>
                              <div className="pic">
                                <img
                                  src={images[session.image]}
                                  alt={session.title}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScheduleSection;
