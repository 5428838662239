import React from 'react';
import './GuestSpeaker.css';

import nosakhareAlexIhama from "../img/GuestSpeakers/nosakhare-alex-ihama.jpg";
import susanHunter from "../img/GuestSpeakers/susan-hunter.jpg";
import johnBrewster from "../img/GuestSpeakers/john-brewster.jpg";
import ruminaMorris from "../img/GuestSpeakers/rumina-morris.jpg";
import carmenPuzzo from "../img/GuestSpeakers/carmen-puzzo.jpg";
import drWendyCukier from "../img/GuestSpeakers/dr-wendy-cukier.jpg";
import drMaryGrogan from "../img/GuestSpeakers/dr-mary-grogan.jpg";
import paulNalli from "../img/GuestSpeakers/paul-nalli.jpg";
import drArdavanEizadirad from "../img/GuestSpeakers/dr-ardavan-eizadirad.jpg";
import drUpiomohOsholene from "../img/GuestSpeakers/dr-upiomoh-osholene.jpg";
import henryLuyombya from "../img/GuestSpeakers/henry-luyombya.jpg";
import moyFung from "../img/GuestSpeakers/moy-fung.jpg";
import jamiePajoel from "../img/GuestSpeakers/jamie-pajoel.jpg";
import ayoOwodunni from "../img/GuestSpeakers/ayo-owodunni.jpg";
import paulineChristian from "../img/GuestSpeakers/pauline-christian.jpg";
import fadhwaYusuf from "../img/GuestSpeakers/fadhwa-yusuf.jpg";
import selehaKhan from "../img/GuestSpeakers/seleha-khan.jpg";
import andreAnderson from "../img/GuestSpeakers/andre-anderson.jpg";
import alethiaOharaStephenson from "../img/GuestSpeakers/alethia-ohara-stephenson.jpg";
import nicholasMarcusThompson from "../img/GuestSpeakers/nicholas-marcus-thompson.jpg";
import robertoHausman from "../img/GuestSpeakers/roberto-hausman.jpeg";

const speakers = [
  { id: 1, name: 'Nosakhare Alex Ihama', image: nosakhareAlexIhama },
  { id: 6, name: 'Dr. Wendy Cukier', image: drWendyCukier },
  { id: 9, name: 'Dr. Ardavan Eizadirad', image: drArdavanEizadirad },
  { id: 16, name: 'Pauline Christian', image: paulineChristian },
  { id: 11, name: 'Henry Luyombya', image: henryLuyombya },
  { id: 15, name: 'Ayo Owodunni', image: ayoOwodunni },
  { id: 17, name: 'Fadhwa Yusuf', image: fadhwaYusuf },
  { id: 8, name: 'Paul Nalli', image: paulNalli },
  { id: 19, name: 'Andre Anderson', image: andreAnderson },
  { id: 20, name: 'Alethia O’Hara Stephenson', image: alethiaOharaStephenson },
  { id: 5, name: 'Carmen Puzzo', image: carmenPuzzo },
  { id: 10, name: 'Dr. Upiomoh Osholene', image: drUpiomohOsholene },
  { id: 22, name: 'Roberto Hausman', image: robertoHausman },
  { id: 18, name: 'Seleha Khan', image: selehaKhan },
  { id: 14, name: 'Jamie Pajoel', image: jamiePajoel },
  { id: 4, name: 'Rumina Morris', image: ruminaMorris },
  { id: 3, name: 'John Brewster', image: johnBrewster },
  { id: 2, name: 'Susan Hunter', image: susanHunter },
  { id: 7, name: 'Dr. Mary Grogan', image: drMaryGrogan },
  { id: 13, name: 'Moy Fung', image: moyFung },
  { id: 21, name: 'Nicholas Marcus Thompson', image: nicholasMarcusThompson },
];

const GuestSpeaker = () => {
  return (
    <div className="header" id="speakers2024">
        <h1 className="title">Speakers and Panelists (2024)</h1>
        <p className="subheader">
        Honour the insights and expertise of our guest speakers and panelist who are shaping the future.
        </p>
        <div className="guest-speaker-grid">
        {speakers.map((speaker) => (
            <div key={speaker.id} className="guest-speaker-item">
            <img src={speaker.image} alt={speaker.name} className="guest-speaker-image" />
            <p className="guest-speaker-name">{speaker.name}</p>
            </div>
        ))}
        </div>
    </div>
  );
};

export default GuestSpeaker;
