// NewsletterSignup.js
import React, { useState } from "react";
import "./NewsletterSignup.css"
import newsletter from "../img/newsletter.webp"

const NewsletterSignup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [statusMessage, setStatusMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email) {
        setStatusMessage("Please enter a valid name and email.");
        return;
    }

    setTimeout(() => {
      setStatusMessage("Thank you for subscribing!");
    }, 1000);

    setName("");
    setEmail("");
  };

  return (
    <div className="newsletter-signup">
        <img className="newsletter-img" src={newsletter} alt="Newsletter" />
        <div className="newsletter-signup-container">
            <h2>Gain FREE Access to a Leadership & Diversity Course</h2>
            <h5>Join thousands of subscribers who have been empowered through our transformational content!</h5>
            <form onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="First and Last Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                />
                <input
                type="email"
                placeholder="Enter your email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit">Sign Up</button>
            </form>
              <p className="statusMessage">{statusMessage}</p>
        </div>
    </div>
  );
};

export default NewsletterSignup;
