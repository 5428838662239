// App.js

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Speakers from "./pages/Speakers";
import Sponsorship from "./pages/Sponsorship";
import Awardees from "./pages/Awardees";
import Footer from "./components/Footer";
import NoPage from "./pages/NoPage";
import "./App.css";
import "./Variable.css";
import ReactGA from "react-ga4";

ReactGA.initialize("G-GNK2KY2M5B");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const App = () => {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/speakers" element={<Speakers />} />
          <Route path="/sponsorship" element={<Sponsorship />} />
          <Route path="/awardees" element={<Awardees />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
