import React from "react";
import "./AwardeesCard.css";

const AwardeesCard = ({ image, name, title, linkedin, award }) => {
    // Determine if the LinkedIn link should be hidden
    const isLinkedInHidden = linkedin === "https://www.linkedin.com";
  
  return (
    <div className="awardees-card">
      <div className="image-section">
        <img src={image} alt={`${name}`} className="awardees-image" />
      </div>
      <div className="awardees-container">
        <h3 className="awardees-name">{name}</h3>
        <div className="social-icons">
          {!isLinkedInHidden && (
            <a
              href={linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          )}
        </div>
      </div>
      <p className="awardees-title">{title}</p>
      <p className="award">{award}</p>
    </div>
  );
};

export default AwardeesCard;
