import GuestSpeaker from "../components/GuestSpeaker";
import GuestSpeaker2023 from "../components/GuestSpeaker2023";


const Speakers = () => {
    return (
    <>
      <GuestSpeaker/>
      <GuestSpeaker2023/>
    </>
    );
  };
  
  export default Speakers;