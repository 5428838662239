import React from 'react';
import './Sponsorship.css';
import data from '../assets/data/data.json';

import sponsor1 from "../img/Sponsorship/sponsor1.jpg";
import sponsor2 from "../img/Sponsorship/sponsor2.jpg";
import sponsor3 from "../img/Sponsorship/sponsor3.jpg";
import sponsor4 from "../img/Sponsorship/sponsor4.jpg";

const Sponsorship = () => {
  const half = Math.ceil(data.valuePropositions.length / 2);
  const firstHalf = data.valuePropositions.slice(0, half);
  const secondHalf = data.valuePropositions.slice(half);

  return (
    <>
      <div className='sponsorship-section'>
        <h2 className="header">Sponsorship Value Propositions</h2>
        <section className="value-grid">
          <div className="grid-item">
            <ol>
              {firstHalf.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ol>
            <div className="center-div">
              <a href="#sponsor-steps" className="primary-btn">Become a Sponsors Now!</a>
            </div>
          </div>
          <div className="grid-item event-img">
            <img src={sponsor1} alt="Event 1" />
            <img src={sponsor2} alt="Event 2" />
          </div>
          <div className="grid-item event-img">
            <img src={sponsor3} alt="Event 3" />
            <img src={sponsor4} alt="Event 4" />
          </div>
          <div className="grid-item">
            <ol start={half + 1}>
              {secondHalf.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ol>
            <div className="center-div">
              <a href="#sponsor-steps" className="primary-btn">Become a Sponsors Now!</a>
            </div>
          </div>
        </section>

        <section className="sponsorship-options">
          <h2 className="header">Sponsorship Options</h2>
          <div className="options-grid">
            {data.sponsorshipOptions.map((option, index) => (
              <div key={index} className="option-item">
                <p className="price">{option.price}</p>
                <p className="description">{option.description}</p>
              </div>
            ))}
          </div>
          <div className="center-div">
            <a href="assets/doc/About-Canada-Leadership-Summit-and-Nation-Builders-Award.pdf" target="_blank" rel="noreferrer" className="primary-btn learn-more">
              Learn More about Canada Leadership Summit and Nation Builders Award!
            </a>
          </div>
        </section>

        <section id="sponsor-steps" className="sponsorship-instructions" >
          <h2 className="header">Sponsorship Instructions</h2>
          <ol className="instructions-list">
            {data.sponsorshipInstructions.map((instruction, index) => (
              <li key={index}>{instruction}</li>
            ))}
          </ol>
        </section>
      </div>
    </>
  );
};

export default Sponsorship;
